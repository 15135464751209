(function () {
  'use strict';

  angular
    .module('learning.resource')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning.resource', {
        url: '/resource?moduleId',
        templateUrl: 'dashboard/learning/resource/resource.tpl.html',
        controller: 'ResourceCreateCtrl',
        controllerAs: 'resourceCreate',
        roles: ['superadmin', 'admin', 'Teacher']
      });
  }
}());
